var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.customerTodos.length
        ? _c("Card", { staticClass: "mt-8" }, [
            _vm.onboardingCompletion
              ? _c("div", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "text-brandPurple text-sm" }, [
                    _vm._v(
                      "Your profile is " +
                        _vm._s(_vm.onboardingCompletion) +
                        "% done"
                    ),
                  ]),
                  _c("p", { staticClass: "text-grey" }, [
                    _vm._v("Complete to enjoy all features"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-3 progress bg-lightGrey-1 w-full rounded-lg",
                    },
                    [
                      _c("div", {
                        staticClass: "inner-progress",
                        style: { width: _vm.onboardingCompletion + "%" },
                      }),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "profile-steps overflow-auto" },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "item" + index,
                    staticClass: "step-card grid p-2 cursor-pointer",
                    style: {
                      background: "" + item.background,
                      display: _vm.handleDisplayStep(item.type),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleDisplay(item.action)
                      },
                    },
                  },
                  [
                    _c("div", {
                      class: [
                        item.action === "personal"
                          ? "personal"
                          : "illustration",
                      ],
                      domProps: { innerHTML: _vm._s(item.icon) },
                    }),
                    _c(
                      "h4",
                      {
                        staticClass: "text-xl font-bold",
                        style: { color: item.headingColor },
                      },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    ),
                    _c("div", { staticClass: "flex items-end justify-end" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-end relative cursor-pointer z-2",
                          on: {
                            click: function ($event) {
                              return _vm.handleDisplay(item.action)
                            },
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mr-2 text-darkGrey text-xs font-semibold",
                            },
                            [_vm._v(" " + _vm._s(item.actionText) + " ")]
                          ),
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                width: "12",
                                height: "11",
                                fill: "none",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M1.33334 6.08283H8.78L5.52667 9.33616c-.26.26-.26.68664 0 .94664s.68.26.94 0L10.86 5.88949c.26-.26.26-.68 0-.94l-4.38666-4.4c-.26-.26-.68-.26-.94 0-.26.26-.26.68 0 .94l3.24666 3.26H1.33334c-.36667 0-.66667.3-.66667.66667s.3.66667.66667.66667Z",
                                  fill: "#1E1452",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("Kyc", {
        attrs: { display: _vm.displayKyc },
        on: { close: _vm.handleCloseKyc, next: _vm.handleDisplay },
      }),
      _c("Checkout", {
        attrs: { display: _vm.showCheckout },
        on: {
          close: function ($event) {
            _vm.showCheckout = false
          },
          success: function ($event) {
            _vm.showCheckout = false
          },
        },
      }),
      _c("CreditQualification", {
        attrs: { display: _vm.displayCreditQualification },
        on: {
          close: _vm.handleCloseCreditQualification,
          next: _vm.handleCheckout,
        },
      }),
      _c("PersonalDetails", {
        attrs: { display: _vm.displayPersonalDetails },
        on: { close: _vm.handleClosePersonalDetails },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }