<template>
    <div>
      <Card class="mt-8"  v-if="customerTodos.length">
      <div class="mb-4" v-if="onboardingCompletion">
        <p class="text-brandPurple text-sm">Your profile is {{ onboardingCompletion }}% done</p>
        <p class="text-grey">Complete to enjoy all features</p>
        <div class="mt-3 progress bg-lightGrey-1 w-full rounded-lg">
          <div class="inner-progress" :style="{ width: `${onboardingCompletion}%` }"></div>
        </div>
      </div>

      <div class="profile-steps overflow-auto">
        <div
          class="step-card grid p-2 cursor-pointer"
          v-for="(item, index) in items"
          :key="'item' + index"
          :style="{
            background: `${item.background}`,
            display: handleDisplayStep(item.type),
          }"
          @click="handleDisplay(item.action)"
        >
          <div :class="[item.action === 'personal' ? 'personal' : 'illustration']" v-html="item.icon"></div>
          <h4 class="text-xl font-bold" :style="{ color: item.headingColor }">
            {{ item.text }}
          </h4>
          <div class="flex items-end justify-end">
            <div class="flex items-center justify-end relative cursor-pointer z-2" @click="handleDisplay(item.action)">
              <p class="mr-2 text-darkGrey text-xs font-semibold">
                {{ item.actionText }}
              </p>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" fill="none">
                <path
                  d="M1.33334 6.08283H8.78L5.52667 9.33616c-.26.26-.26.68664 0 .94664s.68.26.94 0L10.86 5.88949c.26-.26.26-.68 0-.94l-4.38666-4.4c-.26-.26-.68-.26-.94 0-.26.26-.26.68 0 .94l3.24666 3.26H1.33334c-.36667 0-.66667.3-.66667.66667s.3.66667.66667.66667Z"
                  fill="#1E1452"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Card>
      <!-- modal for kyc -->
      <Kyc :display="displayKyc" @close="handleCloseKyc" @next="handleDisplay" />
    <!-- for checkout -->
    <Checkout :display="showCheckout" @close="showCheckout = false" @success="showCheckout = false" />
    <CreditQualification :display="displayCreditQualification" @close="handleCloseCreditQualification" @next="handleCheckout" />
    <PersonalDetails :display="displayPersonalDetails" @close="handleClosePersonalDetails"  />
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Modal } from "@/UI/Modals";
  import { KYC_STEPS, CREDIT_QUALIFICATION_STEPS } from "@/utils/sharedData/home";
  export default {
    components: {
      Modal,
      Kyc: () => import("../Kyc/Kyc.vue"),
      CreditQualification: () => import("../CreditQualification"),
      Checkout: () => import("../Checkout/Checkout.vue"),
      // ActivationReminder: () => import("../ActivationReminder/reminder.vue"),
      PersonalDetails: () => import("../PersonalDetails/PersonalDetails.vue"),
    },

    props: {
      customerTodos: {
        type: Array,
        default: () => [],
        required: true,
      },
      onboardingCompletion: {
        default: null,
      },
    },

    data: () => ({
      showCheckout: false,
      items: [
     
        {
          text: "Verify your Identity",
          headingColor: "#143B52",
          background: `linear-gradient(12.77deg, #f5ebff -74.59%, rgba(245, 235, 255, 0) 101.8%),#d6faff`,
          icon: `<svg class="illustration" xmlns="http://www.w3.org/2000/svg" width="48" height="56" fill="none">
                <path
                    d="M-4 60.001 40 4.00098m0 0L44 32.001M40 4.00098H11"
                    stroke="#0EA2F5"
                    stroke-width="8"
                />
                </svg>`,
          action: "kyc",
          type: "IDENTITY_VERIFICATION",
          actionText: "Activate",
        },
        {
          text: "Personal Details",
          headingColor: "#145238",
          background: `linear-gradient(0deg, #E1FFC6, #E1FFC6), #D6FAFF`,
          icon: `<svg class="personal" xmlns="http://www.w3.org/2000/svg" width="90" height="106" fill="none"><path d="M102.827 5.42683c2.659 8.46307-2.8439 19.21997-6.5283 26.72117-3.3092 6.7373-7.4128 12.879-12.9285 17.9279-4.412 4.0387-12.02 8.5806-18.2331 8.0238-3.2863-.2946-7.2401-4.7765-9.2814-7.1547-2.9796-3.4711-4.0826-9.3238-5.5392-13.5924-2.018-5.914 4.8225-7.7255 9.2769-9.0636 2.8161-.8459 6.0095 1.6774 7.6753 3.6478 2.9136 3.4464 6.9918 7.3075 8.6873 11.5352 5.5587 13.8602 10.5878 26.0284 8.5297 41.2023-1.2201 8.9952-7.8669 12.2687-15.7079 14.9442-5.7714 1.9695-12.304 4.2145-17.3382-.6372-3.2933-3.1739-7.619-6.5148-10.1496-10.2989-4.5088-6.7422-8.1425-14.0719-11.9922-21.2207-3.9776-7.3862-8.1228-16.5619-9.2973-24.9663-.4742-3.3929-.1413-7.5753-1.1063-10.8044-.537-1.797-.9194-3.3002-1.0421-5.2148-.076-1.1852-.2984-2.3923-.3196-3.5772-.001-.0544.1906-1.3758-.0337-1.2992-1.3321.4545-2.2857 4.7573-3.3655 5.9815-2.0356 2.3078-3.7148 5.113-5.51154 7.6149-1.24452 1.733-2.32875 3.5075-3.32068 5.3929-.24807.4714-1.33065 1.5811-.84851 1.3544C9.92722 39.3696 11.4689 30.571 15.2895 26.4086c2.0762-2.262 1.5405-3.2319 1.8838-5.7627.3891-2.8686 2.0161 5.4281 2.9509 8.1679 1.7209 5.0432 6.9114 5.6396 9.8992 9.5652" stroke="#10DF88" stroke-width="8" stroke-linecap="square"/></svg>`,
          action: "personal",
          type: "USER_DETAILS_UPDATE",
          actionText: "Fill details",
        },
        {
          text: "Credit qualification",
          headingColor: "#523F14",
          background: `linear-gradient(12.77deg, #F5EBFF -74.59%, rgba(245, 235, 255, 0) 101.8%), #FFEAD6`,
          icon: `<svg class="illustration" xmlns="http://www.w3.org/2000/svg" width="124" height="100" fill="none"><path d="M105.896 4.75759 90.4084 64.4275l-58.2208-2.4536L-.41355 108.521M105.896 4.75759 85.83 10.1649m20.066-5.40731c1.137 2.25486 9.636 19.10371 13.743 27.24621" stroke="#FFC130" stroke-width="8"/></svg>`,
          action: "credit-qualification",
          type: "CREDIT_QUALIFICATION",
          actionText: "Apply for credit",
        },
        {
          text: "Get cardZilla",
          headingColor: "#1E1452",
          background: `#F7F5FF`,
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="118" height="110" viewBox="0 0 118 110" fill="none"><path d="M111.922 22.3751L51.497 24.6559L39.0713 81.3145L-18.2889 83.7505L-43.454 140.701M111.922 22.3751L101.718 40.5765M111.922 22.3751C110.075 20.8003 96.2736 9.03258 89.6039 3.34559" stroke="#6956C8" stroke-width="8"/></svg>`,
          action: "zilla-card",
          type: "CREATE_FIRST_ZILLA_CARD",
          actionText: "Get #cardZilla",
        },
        // {
        //   text: "Make first purchase",
        //   headingColor: "#145238",
        //   background: `linear-gradient(0deg, #E1FFC6, #E1FFC6), #D6FAFF`,
        //   icon: `<svg xmlns="http://www.w3.org/2000/svg" width="161" height="124" fill="none"><path d="m154.922 22.3751-60.4248 2.2808-12.4258 56.6586-57.3601 2.436L-.45384 140.701M154.922 22.3751l-10.204 18.2014m10.204-18.2014c-1.847-1.5748-15.648-13.34252-22.318-19.02951" stroke="#10DF88" stroke-width="8"/></svg>`,
        //   action: "purchase",
        //   type: "MAKE_FIRST_PURCHASE",
        //   actionText: "Make purchase",
        // },
        {
          text: "Clear late payment",
          headingColor: "#521427",
          background: `linear-gradient(12.77deg, #F5EBFF -74.59%, rgba(245, 235, 255, 0) 101.8%), #FFF1F5`,
          icon: `<svg class="illustration" xmlns="http://www.w3.org/2000/svg" width="124" height="100" fill="none"><path d="M105.896 4.75759 90.4084 64.4275l-58.2208-2.4536L-.41355 108.521M105.896 4.75759 85.83 10.1649m20.066-5.40731c1.137 2.25486 9.636 19.10371 13.743 27.24621" stroke="#FFC130" stroke-width="8"/></svg>`,
          action: "clear-payment",
          type: "CLEAR_OVERDUE_PAYMENT",
          actionText: "Clear",
        },
      ],
    }),

    computed: {
      ...mapState({
        displayKyc: (state) => state.dashboard.kycSteps.display,
        userData: (state) => state?.auth?.user,
        displayCreditQualification: (state) => state.dashboard.creditQualificationSteps.display,
        employmentCreditQualification: (state) =>
          state?.userInfo?.creditQualification?.preConditionResult?.EMPLOYMENT_STATUS_CHECK?.eligibilityStatus,
        bvnValid: (state) => state?.auth?.user?.identityNumberVerified,
        displayPersonalDetails: (state) => state?.dashboard?.personalDetails?.display,
      }),

      handleProgress() {
        // abeg cynthia help me run am, head dey pain me, it's supposed to be the inverse case
        // Me sef no fit run am, hand dey misbehave
        return (this.customerTodos.length / 5) * 100;
      },
    },

    methods: {
      ...mapActions("dashboard", [
        "handleKycStep",
        "showKycStep",
        "handleCreditQualificationStep",
        "showCreditQualificationStep",
        "getDashboardData",
        "handleShowPersonalDetails",
      ]),
      ...mapActions("card", ["updateCardType"]),
      ...mapActions("loading", ["setLoading"]),
      ...mapActions("notification", ["showAlert"]),
      handleDisplay(type) {
        switch (type) {
          case "kyc":
            if (this.userData.identityNumberVerified) {
              if (!this.userData.phoneVerified) {
                this.handleKycStep(KYC_STEPS.phone);
              } else if (!this.userData.selfieVerified) {
                this.handleKycStep(KYC_STEPS.camera);
              } else if (!this.userData.addressSorted) {
                this.handleKycStep(KYC_STEPS.address);
              }
            } else {
              this.handleKycStep(KYC_STEPS.bvn);
            }
            this.showKycStep(true);
            break;
          case "credit-qualification":
              if (this.employmentCreditQualification === "ELIGIBLE") {
                this.handleCreditQualificationStep(CREDIT_QUALIFICATION_STEPS.bank);
              } else {
                this.handleCreditQualificationStep(CREDIT_QUALIFICATION_STEPS.work);
              }
              this.showCreditQualificationStep(true);
            break;
          case "purchase":
            this.showCheckout = true;
            break;
          case "clear-payment":
            this.$router.push("/purchases");
            break;
          case "zilla-card":
            if (this.$growthbook.isOn("issued-card")) {
              this.updateCardType("VIRTUAL");
              this.$router.push({ name: "drawer-customize-card" });
            }
            break;
          case "personal":
            this.handleShowPersonalDetails(true);
            break;
        }
      },
      handleCloseKyc() {
        this.setLoading(true);
        this.getDashboardData()
          .then(() => {
            this.setLoading(false);
            this.showKycStep(false);
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
      handleCloseCreditQualification() {
        this.setLoading(true);
        this.getDashboardData()
          .then(() => {
            this.setLoading(false);
            this.showCreditQualificationStep(false);
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
      handleClosePersonalDetails() {
        this.setLoading(true);
        this.getDashboardData()
          .then(() => {
            this.setLoading(false);
            this.handleShowPersonalDetails(false);
          })
          .catch(() => {
            this.setLoading(false);
          });
      },
      handleCheckout() {
        this.showCheckout = true;
        this.handleCloseCreditQualification();
      },
      handleDisplayStep(item) {
        let displayType;
        if (this.customerTodos?.some((todo) => todo.key === item)) {
          if (item === "CREATE_FIRST_ZILLA_CARD") {
            if (this.$growthbook.isOn("issued-card")) {
              displayType = "grid";
            } else {
              displayType = "none";
            }
          } else {
            displayType = "grid";
          }
        } else {
          displayType = "none";
        }
        return displayType;
      },
      // deprecated functionality
      handleDisabled(item) {
        let disabled;
        // if a previous step exist, it means the current step should be disabled
        switch (item.type) {
          case "IDENTITY_VERIFICATION":
            disabled = false;
            break;
          case "CREDIT_QUALIFICATION":
            disabled = this.customerTodos?.some((todo) => todo.key === "IDENTITY_VERIFICATION");
            break;
          case "MAKE_FIRST_PURCHASE":
            disabled = this.customerTodos?.some((todo) => todo.key === "CREDIT_QUALIFICATION");
            break;
          case "CLEAR_PAYMENT":
            disabled = this.customerTodos?.some((todo) => todo.key === "CREDIT_QUALIFICATION");
            break;
        }
        return disabled;
      },
    },
  };
</script>
<style scoped>
  .progress {
    background-color: #f3f3f6;
    height: 0.375rem;
    margin-top: 0.875rem;
    position: relative;
  }

  .inner-progress {
    background: #6a30c3;
    border-radius: 19px;
    z-index: 2;
    height: 0.375rem;
    transition: all 0.5s linear;
  }

  .profile-steps {
    /* margin-top: 1.5rem; */
    display: grid;
    grid-template-columns: repeat(3, 11.063rem);
    grid-gap: 1rem;
  }

  .step-card {
    position: relative;
    height: 13.126rem;
    border-radius: 8px;
    overflow: hidden;
  }

  .step-card h2 {
    font-family: Gilroy;
    line-height: 120%;
  }

  .step-card .illustration {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .step-card .personal {
    position: absolute;
    bottom: 33px;
    right: 0;
  }

  .disabled {
    opacity: 0.25;
    pointer-events: none;
    cursor: not-allowed;
  }
</style>
